<!--
 * @Description: 职位收藏
 * @Author: huacong
 * @Date: 2021-05-20 14:53:30
 * @LastEditTime: 2021-07-19 17:42:43
 * @LastEditors: huacong
-->
<template>
  <div class="talentBg">
    <section>
      <el-row class="talentIndex" :gutter="16">
        <el-col :span="8">
          <talentInfo></talentInfo>
        </el-col>
        <el-col :span="16">
          <el-tabs v-model="activeName">
            <el-tab-pane label="我收藏的职位" name="first">
              <el-radio-group
                v-model="workType"
                size="small"
                @change="changeWorkType"
                style="margin-bottom: 8px;"
              >
                <el-radio-button label="兼职"></el-radio-button>
                <el-radio-button label="全职"></el-radio-button>
              </el-radio-group>
              <div v-if="partBox">
                <el-table :data="partData" class="partTimeTable">
                  <el-table-column width="450px">
                    <template slot-scope="scope">
                      <div style="padding-left:10px">
                        <div class="cername">
                          <span
                            @click="gopartTime(scope.row.position_id)"
                            class="font-blod cursor"
                          >
                            {{ scope.row.j_certificate }}-{{
                              scope.row.j_major
                            }}
                          </span>
                        </div>
                        <div class="cerstate">
                          <span class="cerPay font-orange">
                            {{ scope.row.j_min_price }}万-{{
                              scope.row.j_max_price
                            }}万/{{ scope.row.j_sign_time }}
                          </span>
                          <span>
                            <span v-if="scope.row.j_social_need == '不限'">
                              社保</span
                            >{{ scope.row.j_social_need }}
                          </span>
                          <span
                            >{{ scope.row.j_area_province }}-{{
                              scope.row.j_area_city
                            }}</span
                          >
                        </div>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column>
                    <template slot-scope="scope">
                      <div>
                        <div class="comName">
                          <span>{{ scope.row.com_name }}</span>
                        </div>
                        <div>
                          <span style="color: #2573F1;">
                            更新时间:{{ scope.row.create_time }}
                          </span>
                        </div>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column width="150px">
                    <template slot-scope="scope">
                      <div style="text-align:right">
                        <el-button
                          type="text"
                          v-small-repeat-click
                          @click="cancelStar(1, scope.row.position_id)"
                          class="font-red"
                        >
                          取消收藏
                        </el-button>
                        <el-button
                          type="text"
                          v-small-repeat-click
                          @click="deliver(scope.row.position_id)"
                        >
                          投递简历
                        </el-button>
                      </div>
                    </template>
                  </el-table-column>
                </el-table>
                <div class="pageBox">
                  <el-pagination
                    background
                    @size-change="sizeChange1"
                    @current-change="currentChange1"
                    :current-page="currentPage"
                    :page-sizes="[10, 20]"
                    :page-size="pagesize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="tableTotal"
                  >
                  </el-pagination>
                </div>
              </div>
              <div v-if="fullBox">
                <el-table :data="fullData" class="fullTimeTable">
                  <el-table-column width="450px">
                    <template slot-scope="scope">
                      <div style="padding-left:10px">
                        <div class="cername">
                          <span
                            @click="gofullTime(scope.row.position_id)"
                            class="font-blod cursor"
                          >
                            {{ scope.row.j_certificate }}-{{
                              scope.row.j_major
                            }}
                          </span>
                        </div>
                        <div class="cerstate">
                          <span class="cerPay font-orange">
                            {{ scope.row.j_min_price }}K-{{
                              scope.row.j_max_price
                            }}K
                          </span>
                          <span>
                            {{ scope.row.j_work_experience }}
                          </span>
                          <span>
                            {{ scope.row.j_education_need }}
                          </span>
                          <span
                            >{{ scope.row.j_province }}-{{
                              scope.row.j_city
                            }}</span
                          >
                        </div>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column>
                    <template slot-scope="scope">
                      <div>
                        <div class="comName">
                          <span>{{ scope.row.com_name }}</span>
                        </div>
                        <div>
                          <span style="color: #2573F1;">
                            更新时间:{{ scope.row.create_time }}
                          </span>
                        </div>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column width="150px">
                    <template slot-scope="scope">
                      <div style="text-align:right">
                        <el-button
                          type="text"
                          v-small-repeat-click
                          @click="cancelStar(2, scope.row.position_id)"
                          class="font-red"
                        >
                          取消收藏
                        </el-button>
                        <el-button
                          type="text"
                          v-small-repeat-click
                          @click="deliver(scope.row.position_id)"
                        >
                          投递简历
                        </el-button>
                      </div>
                    </template>
                  </el-table-column>
                </el-table>
                <div class="pageBox">
                  <el-pagination
                    background
                    @size-change="sizeChange2"
                    @current-change="currentChange2"
                    :current-page="currentPage2"
                    :page-sizes="[10, 20]"
                    :page-size="pagesize2"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="tableTotal2"
                  >
                  </el-pagination>
                </div>
              </div>
            </el-tab-pane>
          </el-tabs>
        </el-col>
      </el-row>
    </section>
  </div>
</template>
<script>
import talentInfo from "@/view/talentPage/talentInfo.vue";
export default {
  components: {
    talentInfo,
  },
  data() {
    return {
      activeName: "first",
      workType: "兼职",
      partData: [],
      fullData: [],
      currentPage: 1,
      pagesize: 10,
      tableTotal: 0,
      currentPage2: 1,
      pagesize2: 10,
      tableTotal2: 0,
      partBox: true,
      fullBox: false,
      searchInfo: {
        type: 1,
      },
      searchInfo2: {
        type: 2,
      },
    };
  },
  methods: {
    // 切换显示兼职全职
    changeWorkType(e) {
      if (e == "兼职") {
        this.fullBox = false;
        this.partBox = true;
      } else {
        this.partBox = false;
        this.fullBox = true;
      }
    },
    // 收藏兼职列表
    getPartTime(page, limit) {
      if (page == undefined || limit == undefined) {
        this.searchInfo.page = 1;
        this.searchInfo.limit = 10;
      } else {
        this.searchInfo.page = this.currentPage;
        this.searchInfo.limit = this.pagesize;
      }
      this.$http
        .post("talent/index/getcollection", this.searchInfo)
        .then((res) => {
          if (res.data.resultCode === 1) {
            this.partData = res.data.resultData.list;
            this.tableTotal = parseInt(res.data.resultData.count);
          }
        });
    },
    sizeChange1(e) {
      this.pagesize = e;
      this.currentPage = 1;
      this.getPartTime(this.currentPage, this.pagesize);
    },
    currentChange1(e) {
      this.currentPage = e;
      this.getPartTime(e, this.pagesize);
    },
    // 收藏全职列表
    getFullTime(page, limit) {
      if (page == undefined || limit == undefined) {
        this.searchInfo2.page = 1;
        this.searchInfo2.limit = 10;
      } else {
        this.searchInfo2.page = this.currentPage2;
        this.searchInfo2.limit = this.pagesize2;
      }
      this.$http
        .post("talent/index/getcollection", this.searchInfo2)
        .then((res) => {
          if (res.data.resultCode === 1) {
            this.fullData = res.data.resultData.list;
            this.tableTotal2 = parseInt(res.data.resultData.count);
          }
        });
    },
    sizeChange2(e) {
      this.pagesize2 = e;
      this.currentPage2 = 1;
      this.getFullTime(this.currentPage2, this.pagesize2);
    },
    currentChange2(e) {
      this.currentPage2 = e;
      this.getFullTime(e, this.pagesize2);
    },
    // 简历详情
    gopartTime(id) {
      this.$router.push({
        name: "partTimeInfo",
        query: { id: id },
      });
    },
    // 简历详情
    gofullTime(id) {
      this.$router.push({
        name: "fullTimeInfo",
        query: { id: id },
      });
    },
    // 取消收藏
    cancelStar(type, id) {
      this.$http
        .post("talent/index/unsetpostion", { type: type, position_id: id })
        .then((res) => {
          if (res.data.resultCode == 1) {
            this.$message.success("取消收藏成功！");
            this.getPartTime(this.currentPage, this.pagesize);
            this.getFullTime(this.currentPage2, this.pagesize2);
          }
        });
    },
    // 兼职投递简历
    deliver(workInfo) {
      this.$http
        .post("index/parjob/jobdetail", { id: workInfo })
        .then((res) => {
          if (res.data.resultCode == 1) {
            let jobInfo = res.data.resultData.job;
            // 投递信息
            let info = {
              publish_uid: jobInfo.j_uid,
              com_type: jobInfo.j_type,
              com_id: jobInfo.j_company_id,
              position_id: jobInfo.id,
              position_name: jobInfo.j_certificate,
              position_type: 1, // 兼职
            };
            this.$http.post("talent/index/delivery", info).then((res) => {
              if (res.data.resultCode == 1) {
                this.$notify({
                  type: "success",
                  title: "投递成功",
                  message: "您已投递成功，请耐心等待招聘人员与您联系！",
                });
              }
            });
          }
        });
    },
  },
  mounted() {
    this.getPartTime();
    this.getFullTime();
  },
};
</script>
<style scoped>
.talentIndex {
  padding-top: 24px;
}
.cerstate {
  padding-top: 2px;
}
.cerstate span {
  color: #999;
}
.cerstate span:not(:last-child) {
  margin-right: 20px;
}
.cerstate .cerPay {
  font-size: 16px;
  color: #ff6f24;
}
/deep/ .partTimeTable .el-table__header-wrapper,
/deep/ .fullTimeTable .el-table__header-wrapper {
  height: 0;
}
</style>
